import React from 'react'
import { useEffect, useState } from "react";
import { apiUrl } from '../Api';
 

export function LOBList(){
    
  const usr_token = localStorage.getItem('authToken');
  const [lobListData, setLobListData] = useState([""]);
  
 
  
  useEffect(() => {
      const getLobListData = async () => {
          const arr = [];
          try {
            fetch((apiUrl.concat('dropdown/lob')), { 
                  method: 'POST', 
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization' : 'Bearer '+ usr_token,
                },
              })
              .then((response) =>
                  response.json()
              )
              .then((response) => {   
                    let result = response.data;
                    console.log('res',response);
                    // if(result.status == true){
                        result?.map((data) => {
                            return arr.push({ value: data.id, label: data.value });
                        });
                        setLobListData(arr)
                        
                    // }else  if(result.status == false){
                    //     navigate('/login')
                    // }
                    
              });
          } catch (error) {
              console.log(error);
          }
      }
      getLobListData();
  }, []);

  return {lobListData};
}
 // product list
export function ProductList(){

    const usr_token = localStorage.getItem('authToken');
    const [productListData, setProductListData] = useState([""]);

    let lobname = sessionStorage.getItem("lobnamemydash");
    
    let existLobName = "";

    if(lobname != '' && lobname != null){
        let lobnamemydash = JSON.parse(lobname);

        existLobName= lobnamemydash.value ;
    }

    console.log('exist lob : ' + existLobName); 

    useEffect(() => {
        const getProductListData = async () => {
            const arr = [];
            try {
                fetch((apiUrl.concat('dropdown/product')), {  
                   // fetch(('http://localhost:8580/gallery/dropdown/product'), { 
                    method: 'POST', 
                    body:JSON.stringify({'lob' : existLobName}), 
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                    },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                    let result = response.data;
                    console.log(result);
                    result?.map((data) => {
                        
                        return arr.push({ value: data.id, label: data.value });
                     });
                     setProductListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getProductListData();
    }, []);
  
    
    return {productListData};
}
  
// Services list
export function ServicesList(){

    const usr_token = localStorage.getItem('authToken');  
    const [serviceListData, setServiceListData] = useState([""]);
   
    useEffect(() => {
        const getServiceListData = async () => {
            const arr = [];
            try {
                fetch((apiUrl.concat('/dropdown/services')), {   
                    method: 'POST', 
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                  },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                   
                    let result = response.data;
                    
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                     
                    setServiceListData(arr)
                });
            } catch (error) {
                console.log(error);
            }
        }
        getServiceListData();
    }, []); 
    
    return {serviceListData};
}



// Supplier  list
export function SupplierList(){

    const usr_token = localStorage.getItem('authToken');  
    const [supplierListData, setSupplierListData] = useState([""]);
   
    useEffect(() => {
        const getSupplierListData = async () => {
            const arr = [];
            try {
              fetch((apiUrl.concat('dropdown/supplier')), {  
               //  fetch(('http://localhost:8580/gallery/dropdown/supplier'), {  
                    method: 'POST', 
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                  },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                   
                    let result = response.data;

                    // if(result.status == true){
                        result?.map((data) => {
                            return arr.push({ value: data.id, label: data.value });
                        });
                        
                        setSupplierListData(arr)

                    // }else  if(result.status == false){
                    //     navigate('/login')
                    // }
                });
            } catch (error) {
                console.log(error);
            }
        }
        getSupplierListData();
    }, []); 
    
    return {supplierListData};
}



// OemName  list
export function OemNamelist(){

    const usr_token = localStorage.getItem('authToken');  
    const [oemListData, setOemListData] = useState([""]); 
   
    useEffect(() => {
        const getData = async () => {
            const arr = [];
            try {
                fetch((apiUrl.concat('dropdown/oemname')), {  
              //  fetch(('http://localhost:8580/gallery/dropdown/oemname'), {  
                    method: 'POST', 
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                  },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                   
                    let result = response.data;
                    // if(result.status == true){
                        result?.map((data) => {
                            return arr.push({ value: data.id, label: data.value });
                        });
                        
                        setOemListData(arr)
                        
                    // }else  if(result.status == false){
                    //     navigate('/login')
                    // }
                });
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, []); 
    
    return {oemListData};
}



// Component family  list
export function Componentfamilylist(){

    const usr_token = localStorage.getItem('authToken');  
    const [componentFamilyData, setFamilyData] = useState([""]); 
   
    useEffect(() => {
        const getData = async () => {
            const arr = [];
            try {
                fetch((apiUrl.concat('dropdown/componentfalily')), {  
               // fetch(('http://localhost:8580/gallery/dropdown/componentfalily'), {  
                    method: 'POST', 
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                  },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                   
                    let result = response.data;
                    // if(result.status == true){
                        result?.map((data) => {
                            return arr.push({ value: data.id, label: data.value });
                        });
                        
                        setFamilyData(arr)
                    // }else  if(result.status == false){
                    //     navigate('/login')
                    // }
                });
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, []); 
    
    return {componentFamilyData};
}


// Component name  list
export function Componentnamelist(){

    const usr_token = localStorage.getItem('authToken');  
    const [componentNameData, setNameData] = useState([""]); 
   
    useEffect(() => {
        const getData = async () => {
            const arr = [];
            try {
               fetch((apiUrl.concat('dropdown/componentname')), {  
               // fetch(('http://localhost:8580/gallery/dropdown/componentname'), {  
                    method: 'POST', 
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                  },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                   
                    let result = response.data;
                    // if(result.status == true){
                        result?.map((data) => {
                            return arr.push({ value: data.id, label: data.value });
                        });
                        
                        setNameData(arr)
                    // }else  if(result.status == false){
                    //     navigate('/login')
                    // }
                });
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, []); 
    
    return {componentNameData};
}


// Component name  list
export function Proposedulpackaginglist(){

    const usr_token = localStorage.getItem('authToken');  
    const [proposedulpackagingData, setpackagingData] = useState([""]); 
   
    useEffect(() => {
        const getData = async () => {
            const arr = [];
            try {
             fetch((apiUrl.concat('dropdown/proposedulpackaging')), {  
              //  fetch(('http://localhost:8580/gallery/dropdown/proposedulpackaging'), {  
                    method: 'POST', 
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                  },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {  
                   
                    let result = response.data;
                    // if(result.status == true){
                        result?.map((data) => {
                            return arr.push({ value: data.id, label: data.value });
                        });
                        
                        setpackagingData(arr)
                    // }else  if(result.status == false){
                    //     navigate('/login')
                    // }
                });
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, []); 
    
    return {proposedulpackagingData};
}

export function CategoryData(){

    const usr_token = localStorage.getItem('authToken');
    const [categoryDropdown, setCategoryDropdown] = React.useState([""]);

    useEffect(() => {
        const getCategoryData = async () => {
            const arr = [];
            try {
                fetch(apiUrl.concat('dropdown/retail_category') , {
                    method: 'POST',
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization' : 'Bearer '+ usr_token,
                    },
                })
                .then((response) =>
                    response.json()
                )
                .then((response) => {
                    let result = response.data;
                    console.log('categoriessss',result);
                    
                    result?.map((data) => {
                        return arr.push({ value: data.id, label: data.value });
                    });
                    setCategoryDropdown(arr)
                });

            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();
    }, []);

    return {categoryDropdown};
}