import React, { useState, useEffect ,useRef, useCallback}  from 'react'; 
import { Routes,Route, NavLink, useNavigate, Link } from 'react-router-dom';
import Select from "react-select"; 
import { apiUrl } from '../Api';
import {LOBList,ProductList,ServicesList,SupplierList,OemNamelist,Componentfamilylist,Componentnamelist,Proposedulpackaginglist,CategoryData} from '../apidata/getapi';
import Loader from '../loader/Loader';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import debounce from 'lodash.debounce';

const NewSearch = () => {

    const username  = localStorage.getItem('name');
    let base64      = require('base-64');
    const navigate  = useNavigate(); 
    const usr_token = localStorage.getItem('authToken'); 

    const { lobListData }                 = LOBList(); 
    const { supplierListData }            = SupplierList(); 
    const { oemListData }                 = OemNamelist(); 
    const { componentFamilyData }         = Componentfamilylist(); 
    const { componentNameData }           = Componentnamelist(); 
    const { proposedulpackagingData }     = Proposedulpackaginglist(); 
    const { categoryDropdown }            = CategoryData(); 
    const [page, setPage]                 = useState(0);

    const typeListDataList = [
        { value: '1', label:  'Approve'},
        { value: '3', label:  'Reject' },
        { value: '0', label:  'Pending' },
    ]

    const areainsqft = [
        { value: '0-25000', label: '0-25000' },
        { value: '25001-50000', label: '25001-50000' },
        { value: '50001-75000', label: '50001-75000' },
        { value: '75001-100000', label: '75001-100000' },
        { value: '100000+', label: '100000+' },
    ];

    const inventoryCapacity = [
        { value: '0-50000', label: '0-50000' },
        { value: '50001-100000', label: '50001-100000' },
        { value: '100001-150000', label: '100001-150000' },
        { value: '150001-200000', label: '150001-200000' },
        { value: '200000+', label: '200000+' },
    ];

    const unitProcessing = [
        { value: '0-25000', label: '0-25000' },
        { value: '25001-50000', label: '25001-50000' },
        { value: '50001-75000', label: '50001-75000' },
        { value: '75001-100000', label: '75001-100000' },
        { value: '100000+', label: '100000+' },
    ];

    let lobname = sessionStorage.getItem("lobnamemydash");
    let lobnamemydash = JSON.parse(lobname);

    console.log('category',categoryDropdown);
    
    let productname = sessionStorage.getItem("productnamemydash");
    let productnamemydash = JSON.parse(productname);

    let servicename = sessionStorage.getItem("servicenamemydash");
    let servicenamemydash = JSON.parse(servicename);

    let suppliername = sessionStorage.getItem("suppliernamemydash");
    let suppliernamemydash = JSON.parse(suppliername);

    let oemname = sessionStorage.getItem("oemnamemydash");
    let oemnamemydash = JSON.parse(oemname);

    let componentFamily = sessionStorage.getItem("componentfamilydash");
    let componentfamilydash = JSON.parse(componentFamily);

    let componentName = sessionStorage.getItem("componentnamedash");
    let componentnamedash = JSON.parse(componentName);

    let proposedName = sessionStorage.getItem("proposednamedash");
    let proposednamedash = JSON.parse(proposedName);

    let typeName = sessionStorage.getItem("typenamedash");
    let typenamedash = JSON.parse(typeName);

    let categoryName = sessionStorage.getItem("categorynamedash");
    let categorynamedash = JSON.parse(categoryName);

    let areainsqftrange = sessionStorage.getItem("areainsqftdash");
    let areainsqftrangedash = JSON.parse(areainsqftrange);

    let inventoryCapRange = sessionStorage.getItem("inventorycapacitydash");
    let inventoryCapRangedash = JSON.parse(inventoryCapRange);

    let unitProcessingRange = sessionStorage.getItem("unitprocessingdash");
    let unitProcessingRangedash = JSON.parse(unitProcessingRange);

    let suggestSearch   = sessionStorage.getItem("suggestionSearch");
    let suggestSeDasharch = JSON.parse(suggestSearch);

    const initialData = {
        leadId             : '',
        leadtype           : '',
        page               : 0,
        lob_name           : lobnamemydash, 
        product_name       : productnamemydash,
        service_name       : servicenamemydash, 
        supplier_name      : suppliernamemydash, 
        oem_name           : oemnamemydash,
        component_family   : componentfamilydash, 
        component_name     : componentnamedash, 
        proposed_name      : proposednamedash, 
        type_name          : typenamedash, 
        category_name      : categorynamedash, 
        area_in_sqft       : areainsqftrangedash, 
        inventory_capacity : inventoryCapRangedash, 
        unit_processing    : unitProcessingRangedash, 
        suggestion_value   : suggestSeDasharch,

    };

    const [inputValue, setInputValue]               = useState('');
    const [suggestions, setSuggestions]             = useState([]);
    const [errorMessage, setErrorMessage]           = useState('');
    const [productListData, setProductListData]     = React.useState([""]);
    const [serviceListData, setServiceListData]     = React.useState([""]);
    const [SolutionData, setSolutionData]           = useState([]);   
    
    const [countData, setCountData]                 = useState(0);
    const [loading, setLoading]                     = useState(false); 
    const [fetchDataEnabled, setFetchDataEnabled]   = useState(0); 
    const [dropdownData, setDropdownData]           = useState(initialData);
    const [suggestionSearchData, setSuggestionSearchData] = useState([]);
    const [lobNamecheck, setLobName]                = React.useState("AUTO"); 

    const handleLOBNameInput = (data) => {

        //Clear  product and service dropdown
        sessionStorage.removeItem("productnamemydash"); 
        sessionStorage.removeItem("servicenamemydash"); 
        setDropdownData(oldValue => ({
            ...oldValue,
            product_name: "", 
            service_name: "",
        }))

        setLobName(data.label);
        sessionStorage.removeItem("lobnamemydash"); 
        sessionStorage.setItem("lobnamemydash", JSON.stringify(data));  

        setDropdownData((oldValue) => ({
          ...oldValue,
          lob_name: data,
        }));
       
        getProductDropdownData();
    };

    const handleProductInput = (data) => { 

        //Clear service dropdown
        sessionStorage.removeItem("servicenamemydash"); 
        setDropdownData(oldValue => ({
            ...oldValue,
            service_name: "",
        }))

        sessionStorage.removeItem("productnamemydash");
        sessionStorage.setItem("productnamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
            ...oldValue,
            product_name: data,
        }));
  
        getServiceDropdownData();
    };

    const handleServicesInput =(data) =>{
        sessionStorage.removeItem("servicenamemydash");
        sessionStorage.setItem("servicenamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          service_name: data,
        }));
    }

    const handleSupplierInput =(data) =>{
        sessionStorage.removeItem("suppliernamemydash");
        sessionStorage.setItem("suppliernamemydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          supplier_name: data,
        }));
    }

    const handleComponentFInput =(data) =>{
        sessionStorage.removeItem("componentfamilydash");
        sessionStorage.setItem("componentfamilydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          component_family: data,
        }));
    }

    const handleComponentNInput =(data) =>{
        sessionStorage.removeItem("componentnamedash");
        sessionStorage.setItem("componentnamedash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          component_name: data,
        }));
    }

    const handleProposedInput =(data) =>{
        sessionStorage.removeItem("proposednamedash");
        sessionStorage.setItem("proposednamedash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          proposed_name: data,
         }));
    }

    const handleTypeInput =(data) =>{
        sessionStorage.removeItem("typenamedash");
        sessionStorage.setItem("typenamedash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          type_name: data,
        }));
    }

    const handleCategoryInput =(data) =>{
        sessionStorage.removeItem("categorynamedash");
        sessionStorage.setItem("categorynamedash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          category_name: data,
         }));
    }

    const handleAreaInSqInput =(data) =>{
        sessionStorage.removeItem("areainsqftdash");
        sessionStorage.setItem("areainsqftdash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
            ...oldValue,
            area_in_sqft: data,
        }));
    }

    const handleInventoryCapInput =(data) =>{
        sessionStorage.removeItem("inventorycapacitydash");
        sessionStorage.setItem("inventorycapacitydash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          inventory_capacity: data,
        }));
    }

    const handleUnitProcessingInput =(data) =>{
        sessionStorage.removeItem("unitprocessingdash");
        sessionStorage.setItem("unitprocessingdash", JSON.stringify(data)); 
        setDropdownData((oldValue) => ({
          ...oldValue,
          unit_processing: data,
        }));
    }

    const getProductDropdownData = async () => {

        const arr = [];
        let lobname = sessionStorage.getItem("lobnamemydash"); 
        let existLobName = ""; 
        if(lobname != '' && lobname != null){
            let lobnamemydash = JSON.parse(lobname); 
            existLobName= lobnamemydash.value ;
        } 

        try {
            fetch((apiUrl.concat('dropdown/product')), {   
                method: 'POST', 
                body:JSON.stringify({'lob' : existLobName}), 
                headers: {
                "Content-Type": "application/json",
                'Authorization' : 'Bearer '+ usr_token,
                },
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {  
                let result = response.data;
                result?.map((data) => {
                    return arr.push({ value: data.id, label: data.value });
                });

                setProductListData(arr)
            });

        } catch (error) {
            console.log(error);
        }
        
    }

    const getServiceDropdownData = async () => {
        const arr = [];
      
        let lobname = sessionStorage.getItem("lobnamemydash"); 
        let existLobName = ""; 
        if(lobname != '' && lobname != null){
            let lobnamemydash = JSON.parse(lobname); 
            existLobName= lobnamemydash.value ;
        } 
       
        let productname = sessionStorage.getItem("productnamemydash");
        let existProjectName = ""; 
        if(productname != '' && productname != null){
            let proName = JSON.parse(productname); 
            existProjectName= proName.value ;
        }  
      
        try {
          fetch((apiUrl.concat('dropdown/services')), {   
            method: 'POST', 
            body:JSON.stringify({'lob' : existLobName,'product' : existProjectName,}), 
            headers: {
              "Content-Type": "application/json",
              'Authorization' : 'Bearer '+ usr_token,
            },
          })
          .then((response) =>
            response.json()
          )
          .then((response) => {  
              let result = response.data;
              result?.map((data) => {
                  
                  return arr.push({ value: data.id, label: data.value });
              });
              setServiceListData(arr)

          });
        } catch (error) {
          console.log(error);
        }
  
    }

    useEffect(() => {
        getProductDropdownData();
        getServiceDropdownData();
    }, []);

    console.log('dropdown',JSON.stringify(dropdownData));
    console.log('lobcheckkkkkk', lobNamecheck);

    const clearFilter = () =>{
        sessionStorage.removeItem("lobnamemydash");
        sessionStorage.removeItem("productnamemydash");
        sessionStorage.removeItem("servicenamemydash"); 
        sessionStorage.removeItem("suppliernamemydash"); 
        sessionStorage.removeItem("oemnamemydash");  
        sessionStorage.removeItem("componentfamilydash"); 
        sessionStorage.removeItem("componentnamedash");
        sessionStorage.removeItem("proposednamedash"); 
        sessionStorage.removeItem("typenamedash"); 
        sessionStorage.removeItem("categorynamedash"); 
        sessionStorage.removeItem("areainsqftdash"); 
        sessionStorage.removeItem("inventorycapacitydash"); 
        sessionStorage.removeItem("unitprocessingdash"); 
        
        setDropdownData({
            lob_name          : "",
            product_name      : "",
            service_name      : "",
            supplier_name     : "",
            oem_name          : "",
            component_family  : "",
            component_name    : "",
            proposed_name     : "",
            type_name         : "",
            category_name     : "",
            area_in_sqft      : "",
            inventory_capacity: "",
            unit_processing   : "",
            suggestion_value  : ""
            
        }) 
        window.location.reload();
        getSolutions();
        getProductDropdownData();
        getServiceDropdownData();
    }

    useEffect(() => {

        const fetchSuggestions = async () => {
            const arr = [];
            try {
            
                fetch((apiUrl.concat('/dropdown/suggestionsearch')), {
                    method: "POST", 
                    body:JSON.stringify({inputValue}),
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization' : 'Bearer '+ usr_token,
                    },
                })
                .then((response) => response.json())
                .then((response) => { 
                    let result = response.data;
                    result?.map((data) => {
                        return arr.push({ value: data.value });
                    });
                    setSuggestions(arr)
                        
                });

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };
    
        if (inputValue!== '') {
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }

    }, [inputValue]);

    const handleSuggestionClick = (value) => {
        console.log("mysearch "+ value)
        setInputValue(value);
        setSuggestions([]);
        return;
    };

    const handleSearch = async () => {
        var searchInput = inputValue;
        console.log("suggetion  search result api " + searchInput);
    } 

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('name');
        localStorage.removeItem('isAuthenticatedUser');
        localStorage.removeItem('usrId');
        localStorage.removeItem('usrRole');
        navigate('/');
    };

    useEffect(() => {
        if (fetchDataEnabled==0) {
          console.log("Fetching data...");
          getSolutions();

        }else{
          console.log("no Fetching data...");
        }
    }, [fetchDataEnabled]);  
  
    const searchResult = () => {
        window.location.reload();
        setPage(0); 
        setDropdownData((oldValue) => ({
            ...oldValue,
            page: 0
        }));
        setPage(prevPage => 0); 
        setDropdownData((oldValue) => ({
            ...oldValue,
            page: 0
        }));
        console.log('count page -------------'+ page); 
       // return;
        setFetchDataEnabled(1);
        setPage(0); 
        setSolutionData([]);
        getSolutions();
    }; 

    useEffect(() => {
         
    }, [dropdownData]);  

    const getSolutions = async (e) => { 
     
        setLoading(true);
  
        console.log(JSON.stringify(dropdownData));
         
        try { 
          
            fetch((apiUrl.concat('category/search')), {            
                method: "POST", 
                body:JSON.stringify(dropdownData),
                headers: {
                "Content-Type": "application/json",
                'Authorization' : 'Bearer '+ usr_token,
                },
            })
            .then((response) => response.json())
            .then((response) => {
                setLoading(false);
                
                if(response.status == false){ 
                    setErrorMessage("No Results Found !");
                    setSolutionData([])
                    navigate('/');
                }else{ 
                    setErrorMessage("View Solution !");
                    let newData = response.solution;  
                    
                    setSolutionData(prevData => [...prevData, ...newData]); 
                    setPage(prevPage => prevPage + 9); 
                    setDropdownData((oldValue) => ({
                        ...oldValue,
                        page: oldValue.page + 9
                    }));
                    
                    const dataCount = response.solutionCount;
                    setCountData(dataCount);
                    
                }
            });

        } catch (error) {
            console.log(error);
        }
    };

    const handleLoadMore = () => {
        getSolutions();
    }; 
  
    const handleLinkClick = () => {
        window.scrollTo(0, 0); // Scrolls to the top of the page
    };  

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    useEffect(() => {
        console.log('Suggestion search data updated:', suggestionSearchData);
    }, [suggestionSearchData]);  

    const handleOnSearch = (string, results) => {
        console.log("onsearch");
        console.log(string, results);
        // apicall(string);
    };

    const apicall = useCallback( 
        debounce((query) => {
          if (query.length > 0) {
            setLoading(true);
    
            fetch(apiUrl.concat('dropdown/suggestionsearch'), {
              method: 'POST',
              body: JSON.stringify({ inputValue: query }),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + usr_token,
              }
            })
              .then((response) => response.json())
              .then((data) => {
                setLoading(false);
                if (data.status === true) {
                  setSuggestionSearchData(data.data);
                } else {
                  setSuggestionSearchData([]);
                }
              })
              .catch((error) => {
                setLoading(false);
                console.error('Error fetching suggestions:', error);
              });
          } else {
            setSuggestionSearchData([]);
          }
        }, 300), // Adjust delay as needed
        [] // Empty dependency array
    );  

    const handleOnHover = (result) => {
        console.log("onhover");
        console.log(result);
    };
    
    const handleOnSelect = (item) => {
        console.log("onselect");
        sessionStorage.removeItem("suggestionSearch");
        sessionStorage.setItem("suggestionSearch", JSON.stringify(item)); 
        setDropdownData((oldValue) => ({
            ...oldValue,
            suggestion_value: item,
        }));
        getSolutions();
    };

    const handleOnFocus = () => {
        console.log("Focused");
    };

    const handleOnClear = () => {
        console.log("Cleared");
        sessionStorage.removeItem("suggestionSearch"); 
        setDropdownData(oldValue => ({
            ...oldValue,
            suggestion_value: "", 
        }))
    };


    return (
        <>
            {loading && (
                <>
                <Loader/>
                </>
            )}
            <div className="container-fluid nav-bar bg-transparent">
                <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
                    <Link to={'/'}>
                        <a  className="navbar-brand d-flex align-items-center text-center">
                            <div className="icon p-2 me-2">
                                <img className="img-fluid" src="img/holisollogo.jpeg" alt="Icon" style={{ width: '120px' }} />
                            </div>
                            <h1 className="m-0 text-primary">STUDIO</h1>
                        </a>
                    </Link>
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto">
                            <div className="wrap">
                                <div className="searchable" id="search" style={{width:'300px', textAlign:'center', position:'relative'}}> 
                                    <ReactSearchAutocomplete
                                        style={{width:'50%'}}
                                        items={suggestionSearchData}
                                        onSearch={apicall}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        onClear={handleOnClear}
                                        styling={{ zIndex:999 }}
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div className="nav-item dropdown">
                                <div className="icon3" style={{marginRight:'56px'}}>
                                    <img className="img-fluid" src="../img/user.png" alt="Icon" /><br></br>
                                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><strong>{username}</strong></a>
                                    <div className="dropdown-menu ">
                                        <Link onClickCapture={logout} className="dropdown-item">Logout</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="container-fluid bg-primary mb-5 " id="fluid" data-wow-delay="0.1s" >
                    <div className="container">
                        <div className="row g-2">
                            <div className="col-md-10">
                                <div className="row g-2">
                                    <div className="col-md-2">
                                        <label className='text-white'>  Lob</label>
                                        <Select 
                                            className="border-0 "
                                            classNamePrefix="select Lob"
                                            name="lob_name"
                                            options={lobListData}
                                            defaultValue={lobnamemydash}
                                            key={lobnamemydash}
                                            onChange={handleLOBNameInput}
                                        /> 
                                    </div>
                                    <div className="col-md-2">
                                        <label className='text-white'>  Products</label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select Project"
                                            name="product_name"
                                            options={productListData}
                                            onChange={handleProductInput}
                                            defaultValue={productnamemydash}
                                            key={productnamemydash}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='text-white' >  Services</label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="service_name"
                                            options={serviceListData} 
                                            onChange={handleServicesInput}
                                            defaultValue={servicenamemydash}
                                            key={servicenamemydash}
                                        /> 
                                    </div>
                                    {lobnamemydash != '' && lobnamemydash != null && lobnamemydash.label == 'AUTO' && 
                                    <>
                                        <div className="col-md-2">
                                            <label className='text-white' >Supplier Name</label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="supplier_name"
                                                options={supplierListData} 
                                                onChange={handleSupplierInput}
                                                defaultValue={suppliernamemydash}
                                                key={suppliernamemydash}
                                            /> 
                                        </div>
                                        {/*  <div className="col-md-2">
                                            <label className='text-white' >OEM Name</label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="oem_name"
                                                options={oemListData}  
                                                onChange={handleOemInput}
                                                defaultValue={oemnamemydash}
                                                key={oemnamemydash}
                                            /> 
                                        </div> */}
                                        <div className="col-md-2">
                                            <label className='text-white' >Component Family</label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="component_family" 
                                                options={componentFamilyData} 
                                                onChange={handleComponentFInput}
                                                defaultValue={componentfamilydash}
                                                key={componentfamilydash}
                                            /> 
                                        </div>
                                        <div className="col-md-2">
                                            <label className='text-white' >Component Name</label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="component_name"
                                                options={componentNameData}
                                                onChange={handleComponentNInput}
                                                defaultValue={componentnamedash}
                                                key={componentnamedash}
                                            /> 
                                        </div>
                                        <div className="col-md-2">
                                            <label className='text-white' >Proposed UL Packaging</label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="ul_packaging"
                                                options={proposedulpackagingData} 
                                                onChange={handleProposedInput}
                                                defaultValue={proposednamedash}
                                                key={proposednamedash}
                                            /> 
                                        </div>
                                        <div className="col-md-2">
                                            <label className='text-white' >Type</label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="type"
                                                options={typeListDataList} 
                                                onChange={handleTypeInput}
                                                defaultValue={typenamedash}
                                                key={typenamedash} 
                                            /> 
                                        </div>
                                    </>
                                    }
                                    {lobnamemydash != '' && lobnamemydash != null && lobnamemydash.label == 'RETAIL' && 
                                    <>
                                    <div className="col-md-2">
                                        <label className='text-white'> Categories</label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select Project"
                                            name="category_name"
                                            options={categoryDropdown}
                                            onChange={handleCategoryInput}
                                            defaultValue={categorynamedash}
                                            key={categorynamedash}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='text-white'> Area in sq. ft.</label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select Project"
                                            name="category_name"
                                            options={areainsqft}
                                            onChange={handleAreaInSqInput}
                                            defaultValue={areainsqftrangedash}
                                            key={areainsqftrangedash}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='text-white'> Inventory Capacity </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select Project"
                                            name="category_name"
                                            options={inventoryCapacity}
                                            onChange={handleInventoryCapInput}
                                            defaultValue={inventoryCapRangedash}
                                            key={inventoryCapRangedash}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className='text-white'> Units Processing</label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select Project"
                                            name="category_name"
                                            options={unitProcessing}
                                            onChange={handleUnitProcessingInput}
                                            defaultValue={unitProcessingRangedash}
                                            key={unitProcessingRangedash}
                                        />
                                    </div>
                                    </>
                                    }
                                    <div className="col-md-2 right">
                                        <button type="button" title='  Search'  onClick={searchResult}  className="searchButton" style={{height: '41px !important',marginTop:'15px', borderRadius:'5px'}}><span className="fa fa-search"></span></button> 
                                        <Link onClick={clearFilter} ><img className="img-fluid" style={{height: '43px', marginLeft: '20px', marginTop: '-12px'}} src="img/filter.png"  alt="Icon" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 right" style={{marginTop:'25px'}}>
                                <h6>Total Results: <span>{countData}</span></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="container-xxl py-5" style={{marginTop:'50px'}}>
                <div className="container">
                    <div className="row g-0 gx-5 align-items-end">
                        <div className="col-lg-6">
                            <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                                <h1 className="mb-3">Solutions </h1>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div id="tab-1" className="tab-pane fade show p-0 active">
                            <div className="row g-4"> 
                                {SolutionData != '' && SolutionData.map((SolutionData)=> { 
                                    let ext = getExtension(SolutionData.name); 
                                    return(
                                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="property-item rounded overflow-hidden">
                                                <div className="position-relative overflow-hidden">
                                                    <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type} onClick={handleLinkClick}> 
                                                        {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                                            <img className="img-fluid" src={SolutionData.ImgUrl}  style={{height:'300px' ,width:'405px'  }}/> 
                                                        } 
                                                        { (SolutionData.ImgUrl != '') && (ext == 'ppt' || ext == 'xlsx' || ext == 'pptx' ) &&
                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                        }
                                                        { (SolutionData.ImgUrl != '') && (ext == 'docx') &&
                                                            <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}#wdSlideId=3`} style={{height:'300px' ,width:'405px'  }} position="absolute" > </iframe>
                                                        }
                                                        { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                                            <iframe   src= {`${SolutionData.ImgUrl}#toolbar=0&navpanes=0&#toolbar=0&scrollbar=0`} style={{height:'300px' ,width:'405px'  }}></iframe>
                                                        }
                                                    </Link>
                                                    { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                                        <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View Pdf'><i className="fa fa-search"></i></a></div>
                                                    }
                                                    {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                                        <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View '><i className="fa fa-search"></i></a></div>
                                                    }
                                                </div>
                                                <div className="p-4 pb-0">
                                                    <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type}  className="d-block h5 mb-2" href=""  onClick={handleLinkClick}><marquee behavior="scroll" direction="left">{SolutionData.name} </marquee></Link>
                                                </div>
                                            </div>
                                        </div>
                                    )})
                                }
                                {SolutionData == '' && 
                                    <p style={{fontSize:"20px", color:'red', textAlign:"center"}}>Data Not Found !!</p>
                                }
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s" style={{margin: '100px 0'}}>
                    { countData   !=  SolutionData.length   && (
                        <button  className='btn btn-primary' onClick={handleLoadMore}  >
                            Browse More Solution 
                        </button>
                    )}
                </div>  
            </div>
        </>
    )

}

export default NewSearch